import dynamic from "next/dynamic";
import Image from "next/image";
import Link from "next/link";

import Icon from "@/components/icon/Icon";
import labels from "@/config/labels";

const Rating = dynamic(() => import("@mui/material/Rating"), { ssr: false });

const AffiliatesItem = ({ sportsbook }) => {
  const logoPosition = ["SportsBetting", "everygame", "betwhale"].includes(
    sportsbook?.title
  );

  const affiliateStyle = `flex flex-row items-center justify-between rounded-t-md bg-white border border-card-border border-b-0 p-1 ${logoPosition ? "pl-0" : "pl-2"}`;

  return (
    <>
      <div
        className="relative w-[270px] cursor-pointer rounded-lg pb-2"
        style={{ overflow: "hidden", height: "220px" }}
      >
        <Link
          href={sportsbook?.affiliateUrl ?? ""}
          target="_blank"
          rel="sponsored"
          data-event-label={`${sportsbook?.title?.toLowerCase()}-sportsbook-carousel`}
        >
          <Image
            src={sportsbook?.background}
            alt={`${sportsbook?.title} background`}
            fill
            className="rounded-xl"
            loading="eager"
            quality={85}
            sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw"
            srcSet={`${sportsbook?.background}?w=300 300w, ${sportsbook?.background}?w=600 600w, ${sportsbook?.background}?w=1200 1200w`}
            style={{ objectFit: "cover" }}
          />
          <div
            className={affiliateStyle}
            style={{ position: "relative", zIndex: 1 }}
          >
            <Image
              src={sportsbook?.affiliateImageUrl}
              alt={sportsbook?.title}
              width={94}
              height={30}
              className="h-[30px] w-auto rounded-t-lg"
              loading="eager"
              quality={85}
              sizes="(max-width: 600px) 100vw, (max-width: 1200px) 50vw, 33vw"
              srcSet={`${sportsbook?.affiliateImageUrl}?w=94 94w, ${sportsbook?.affiliateImageUrl}?w=188 188w`}
            />
            <Rating
              name="half-rating-read"
              defaultValue={sportsbook.rating}
              size="small"
              precision={0.5}
              readOnly
            />
          </div>
          <div
            className="rounded-b-md p-2"
            style={{ position: "relative", zIndex: 1 }}
          >
            <div className="text-md font-extrabold">
              <p>
                {`${(labels.picksCTAPrefix || "").toUpperCase()} ${(sportsbook?.affiliateCta || "").toUpperCase()?.replace("»", "")}`}
                {`${(labels.affiliateTitlePostfix || "").toUpperCase()}`}
              </p>
            </div>
            <div className="my-2 text-sm">
              {Object.values(sportsbook.valueProposition).map(
                (valueProposition, index) => (
                  <div
                    key={index}
                    className="flex content-center items-center gap-1"
                  >
                    <Icon
                      name="navPicks"
                      color="#15bb9d"
                      width={12}
                      height={12}
                    />
                    <p>{valueProposition}</p>
                  </div>
                )
              )}
            </div>
          </div>
          <div
            id="bettingnews-content"
            style={{ position: "relative", zIndex: 1, textAlign: "right" }}
          >
            <button
              className="btn btn-success btn-sm mr-2 !text-[.81rem]"
              type="button"
            >
              {labels.affiliateCTA}
            </button>
          </div>
        </Link>
        <div onClick={(ev) => ev.stopPropagation()}>
          <Link
            href={sportsbook?.review ?? ""}
            rel="review"
            style={{ position: "relative", zIndex: 1, bottom: "30px" }}
            className="pl-4 text-sm text-white"
          >
            {labels.affiliatesReview}
          </Link>
        </div>
      </div>
    </>
  );
};

export default AffiliatesItem;
