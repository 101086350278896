import Link from "next/link";

import Icon from "@/components/icon/Icon";
import labels from "@/config/labels";
import { SIDE_MENU } from "@/config/menus";

const GuidesCard = () => {
  return (
    <div className="mb-4 rounded-lg border border-card-border bg-white text-dark last:mb-0 lg:shadow-lg">
      <h2 className="pl-6 pt-4 text-[1.375rem] font-bold">
        {labels.rightMenuSportGuide}
      </h2>
      <ul className="m-4 pl-4 font-body leading-9 tracking-[.01em]">
        {Object.values(SIDE_MENU).map((option) => {
          return (
            <li key={option.id} aria-label={option?.name}>
              <Link
                href={option.url ?? ""}
                className="flex flex-row items-center"
              >
                <Icon
                  className="mr-2"
                  height={"16px"}
                  name={`${option?.id}Guide`}
                  width={"20px"}
                  color="currentColor"
                />
                {option?.name}
              </Link>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default GuidesCard;
